import styled from 'styled-components'

interface Props {
  screen?: boolean
  isSmall?: boolean
  addLeftSpacing?: boolean
  appConfig?: any
}

export const StyledJurisdictionHeader = styled.div<Props>`
  box-sizing: border-box;
  font-family: inherit;
  color: ${(props) => props.appConfig.textColor};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.borderColor};
  background-color: ${(props) => props.appConfig.jurisdictionHeaderColor};
  font-weight: bold;
  text-transform: capitalize;
  height: ${(props: Props) => (props.isSmall ? '4.78vh' : '4.78vh')};
  margin: 0;
  display: grid;
  grid-template-columns: 3fr 1.1fr;
  padding-left: 5vw;
  padding-left: ${(props: Props) => (props.addLeftSpacing ? '12.6vw' : '5vw')};
  align-items: center;

  h1 {
    box-sizing: border-box;
    font-size: ${(props: Props) => (props.isSmall ? '3vh' : '3.6vh')};
    align-self: center;
    margin: 0;
    padding: 0;
    color: ${(props: Props) =>
      props.screen
        ? `${props.appConfig.titleColor}`
        : `${props.appConfig.majorColor}`};
    white-space: nowrap;
  }

  p {
    box-sizing: border-box;
    font-size: 1.1vh;
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    padding: 0 0 1.1vh 0.3vh;
    align-self: flex-end;
    width: 15vh;
  }
`

export const StyledNext = styled.div<Props>`
  p {
    padding: 0;
    font-size: 1.5vh;
  }
`
