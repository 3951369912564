import styled from 'styled-components'

interface Props {
  appConfig?: any
}

export const StyledLobbyScreen = styled.div<Props>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.appConfig.textColor};
  font-family: inherit;
  box-sizing: border-box;
  height: 100vh;
  background-color: ${(props) => props.appConfig.minorColor};
`

export const StyledJurisdictionHeader = styled.h1<Props>`
  display: flex;
  align-items: center;
  font-family: inherit;
  color: 'black';
  border-bottom: 0.2vh solid ${(props) => props.appConfig.borderColor};
  background-color: ${(props) => props.appConfig.jurisdictionHeaderColor};
  font-weight: bold;
  text-transform: capitalize;
  height: 4.5vh;
  margin: 0;
  font-size: 3.6vh;
  padding-left: 12.6vw;
`

export const StyledProgressWrapper = styled.div`
  background: #f4f2f2;
  font-size: 1.8vh;
  height: 3.4172vh;
  text-indent: 5.6vw;
`
